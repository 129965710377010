import { getMessageUniqueId } from './common.util';

export const buildParticipantParams = userData => {
  return [
    {
      access_role_id: 22,
      account_id: userData.account_id,
      activity_id: 0,
      asset_datetime_last_seen: '1970-01-01 00:00:00',
      asset_first_name: userData.asset_first_name,
      asset_id: userData.asset_id,
      asset_image_path: userData.asset_image_path,
      asset_last_name: userData.asset_last_name,
      asset_phone_number: userData.asset_phone_number,
      asset_phone_number_code: userData.asset_phone_country_code,
      asset_type_category_id: 3,
      asset_type_id: userData.asset_type_id,
      field_id: 0,
      log_asset_id: userData.asset_id,
      message_unique_id: getMessageUniqueId(userData.asset_id),
      operating_asset_first_name: userData.operating_asset_first_name,
      organization_id: userData.organization_id,
      workforce_id: userData.workforce_id,
    },
  ];
};
