/* eslint-disable valid-typeof */
import { LS_LOGIN_MODE } from '../components/login/login.util'
import CryptoJS from 'crypto-js'
import {
	AUTH_ACCOUNT_ID,
	AUTH_ASSET_ID,
	AUTH_ORGANIZATION_ID,
	AUTH_WORKFORCE_ID,
	AUTH_ASSET_TYPE_ID,
	AUTH_ASSET_IMAGE_PATH,
	AUTH_ASSET_TOKEN_AUTH,
	AUTH_LOGIN_MODE,
	S3_BUCKET_NAME,
	ORGANIZATION_ONHOLD,
	IS_EXTERNAL,
	AUTH_BUCKET_NAME,
	ORG_FLAG_GRID_ENABLE,
	ORGANIZATION_ENABLE_RESOURCE_FILTER,
	ORGANIZATION_SAVED_IMAGE_PATH,
} from './constant'

import moment from 'moment'
import { shortenUrl } from '../services/form.service'
import { outlookConst } from './action.constant'

export const greeting = () => {
	let now = new Date()
	let hrsnow = now.getHours()

	if (hrsnow < 12) {
		return 'Good Morning '
	} else if (hrsnow < 18) {
		return 'Good Afternoon '
	} else {
		return 'Good Evening '
	}
}
export const encryptData = data => {
	var bytes = CryptoJS.AES.encrypt(data.toString(), 'lp-n5^+8M@62')
	const wordArray = CryptoJS.enc.Utf8.parse(bytes.toString())
	return CryptoJS.enc.Base64.stringify(wordArray)
}

export const decryptData = data => {
	const cipherWordArray = CryptoJS.enc.Base64.parse(data.toString())
	const parseCipher = cipherWordArray.toString(CryptoJS.enc.Utf8)
	var bytes = CryptoJS.AES.decrypt(parseCipher.toString(), 'lp-n5^+8M@62')
	return bytes.toString(CryptoJS.enc.Utf8)
}

export const getLocalAuthDetails = () => {
	let organization_onhold = localStorage.getItem(ORGANIZATION_ONHOLD)
	return {
		organization_id: localStorage.getItem(AUTH_ORGANIZATION_ID),
		account_id: localStorage.getItem(AUTH_ACCOUNT_ID),
		workforce_id: localStorage.getItem(AUTH_WORKFORCE_ID),
		asset_id: localStorage.getItem(AUTH_ASSET_ID),
		auth_asset_id: localStorage.getItem(AUTH_ASSET_ID),
		asset_type_id: localStorage.getItem(AUTH_ASSET_TYPE_ID),
		asset_token_auth: localStorage.getItem(AUTH_ASSET_TOKEN_AUTH),
		asset_image_path: localStorage.getItem(AUTH_ASSET_IMAGE_PATH),
		organization_flag_enable_grid: localStorage.getItem(ORG_FLAG_GRID_ENABLE),
		organization_onhold: organization_onhold
			? parseInt(organization_onhold)
			: -1,
		organization_flag_enable_resource_filter: localStorage.getItem(
			ORGANIZATION_ENABLE_RESOURCE_FILTER
		),
		device_os_id: 5,
		service_version: 0.1,
		app_version: 1.0,
	}
}

export const setLocalAuthDetails = payload => {
	const {
		organization_id,
		account_id,
		workforce_id,
		asset_id,
		asset_type_id,
		asset_token_auth = '',
		asset_encryption_token_id = '',
		asset_image_path = '',
		is_external = 'false',
		s3BucketName = 'null',
		organization_flag_enable_grid = 0,
	} = payload
	localStorage.setItem(AUTH_ORGANIZATION_ID, organization_id)
	localStorage.setItem(AUTH_ACCOUNT_ID, account_id)
	localStorage.setItem(AUTH_WORKFORCE_ID, workforce_id)
	localStorage.setItem(AUTH_ASSET_ID, asset_id)
	localStorage.setItem(AUTH_ASSET_TYPE_ID, asset_type_id)
	localStorage.setItem(AUTH_ASSET_IMAGE_PATH, asset_image_path)
	localStorage.setItem(IS_EXTERNAL, is_external)
	localStorage.setItem(
		AUTH_ASSET_TOKEN_AUTH,
		asset_token_auth || asset_encryption_token_id
	)
	localStorage.setItem(AUTH_BUCKET_NAME, s3BucketName)
	localStorage.setItem(ORG_FLAG_GRID_ENABLE, organization_flag_enable_grid)
}

export const removePreviousAuthDetails = () => {
	localStorage.removeItem(ORGANIZATION_SAVED_IMAGE_PATH)
}

export const getShortName = name => {
	if (!!name) {
		let nameArray = name.split(' ')
		return `${nameArray[0]} ${
			nameArray[1] ? nameArray[1][0].toUpperCase() : ''
		}.`
	}
}

export const getMessageUniqueId = asset_id => {
	if (!!asset_id) {
		return asset_id + (Date.now() + Math.floor(100000 + Math.random() * 999999))
	} else {
		let { asset_id: assetId } = getLocalAuthDetails()
		return assetId + (Date.now() + Math.floor(100000 + Math.random() * 999999))
	}
}

export const sanitizeContent = payload => {
	if (!payload) {
		return payload
	}
	// exp 1: to remove opening an closing sqaure brackets
	// exp 1: to remove the asset id enclose by parenthesis
	// exp 1: to remove html markup.
	return payload
		.replace(/\[|]/g, '')
		.replace(/\(\d+\)/g, '')
		.replace(/\n+/g, '\n')
		.replace(/(<([^>]+)>)/gi, '')
}
// TODO: Refactored as this is not required
export const randomCode6 = () => {
	return Math.floor(100000 + Math.random() * 900000)
}

export const AuthMode = () => parseInt(sessionStorage.getItem(AUTH_LOGIN_MODE))
export const formatCoverDate = datetime => {
	if (typeof datetime === 'undefined') return moment().format('DD/MM/YYYY')
	else return moment(datetime).format('DD/MM/YYYY')
}

export const formatExportCoverDate = datetime => {
	if (typeof datetime === 'undefined') return moment().format('DD/MM/YYYY')
	else return moment(datetime).add(330, 'minutes').format('DD/MM/YYYY')
}

export const formatDDMMYYYYHHMM = datetime => {
	if (typeof datetime === 'undefined')
		return moment().format('DD-MM-YYYY HH:mm')
	else return moment(datetime).format('DD-MM-YYYY HH:mm')
}
export const formatLocalDDMMYYYYHHMM = datetime => {
	if (typeof datetime === 'undefined')
		return moment().format('DD-MM-YYYY HH:mm')
	else
		return moment(datetime).subtract(330, 'minutes').format('DD-MM-YYYY HH:mm')
}
export const formatDDMMMYYYY = datetime => {
	if (typeof datetime === 'undefined') return moment().format('DD-MM-YYYY')
	else return moment(datetime).format('DD-MM-YYYY')
}
export const getLocalTimeDate = datetime => {
	if (!datetime) {
		return moment().format('h:mm A, ddd D MMM YYYY')
	} else {
		const utcTime = moment.utc(datetime)
		return utcTime.local().format('h:mm A, ddd D MMM YYYY')
	}
}
export const getISTtoUTC = datetime => {
	if (typeof datetime === 'undefined')
		return moment().format('YYYY-MM-DD HH:mm:ss')
	else
		return moment(datetime)
			.subtract(330, 'minutes')
			.format('YYYY-MM-DD HH:mm:ss')
}
export const getCurrentDateUTCEOD = datetime => {
	if (typeof datetime === undefined) {
		return moment().utc().format('YYYY-MM-DD') + ' 23:59:59'
	} else {
		let endDate = moment(datetime).format('YYYY-MM-DD') + ' 23:59:59'
		return getISTtoUTC(endDate)
	}
}
export const timeAgo = datetime => {
	if (typeof datetime === 'undefined' || datetime === '')
		datetime = getCurrentDateUTC()
	return moment(datetime).fromNow()
}

export const getCurrentDateUTC = datetime => {
	if (typeof datetime === 'undefined')
		return moment().utc().format('YYYY-MM-DD HH:mm:ss')
	else return moment(datetime).utc().format('YYYY-MM-DD HH:mm:ss')
}

export const getCurrentTimeDateISTTemp = datetime => {
	if (typeof datetime === 'undefined')
		return moment().format('h:mm A, ddd D MMM YYYY')
	else return moment(datetime).format('h:mm A, ddd D MMM YYYY')
}

export const timelineDateFormatShort = datetime => {
	return moment(datetime).format('lll')
}

export const toSnakeCase = label => {
	if (!label) {
		return ''
	}
	return label.toLowerCase().split(' ').join('-')
}

export const getS3BucketName = () => {
	return localStorage.getItem(S3_BUCKET_NAME)
}

export const getUrlId = async (custEmail, jsonData) => {
	const { form_id, activity_id, form_flag_prefill_enabled, ...rest } = jsonData
	let reqData = {
		organization_id: jsonData.organization_id,
		account_id: jsonData.account_id,
		workforce_id: jsonData.workforce_id,
		asset_id: jsonData.asset_id,
		auth_asset_id: 31347,
		form_id,
		activity_id,
		asset_token_auth: '05986bb0-e364-11e8-a1c0-0b6831833754',
		url_form_data: JSON.stringify({
			form_id,
			activity_id,
			form_flag_prefill_enabled,
			...rest,
		}),
		form_flag_prefill_enabled: form_flag_prefill_enabled,
		url_mail_receivers: custEmail,
	}
	let returnValue = 0
	await shortenUrl(reqData).then(res => {
		if (res.data.response.length > 0) {
			returnValue = res.data.response[0]
			return res.data.response[0]
		} else {
			return returnValue
		}
	})
	return reqData
}

export const validateEmailUsernamePassword = ({
	operating_asset_username,
	asset_email_password,
	asset_password_expiry_datetime,
	asset_email_id,
	is_password_set,
}) => {
	let alertMsg = ''
	// Decrypting password to check whether it's blank or not
	const bytes = CryptoJS.AES.decrypt(asset_email_password, 'lp-n5^+8M@62')
	const originalText = bytes.toString(CryptoJS.enc.Utf8)

	const currentTime = new Date()
	const isExpiredPassword =
		currentTime.getTime() < Date.parse(asset_password_expiry_datetime)

	const isEmailSet = !!asset_email_id
	const isUsernameSet = !!operating_asset_username
	const isPasswordSet =
		!!is_password_set &&
		is_password_set.toUpperCase() === outlookConst.SET &&
		!!originalText

	if (isUsernameSet && isPasswordSet && isEmailSet && isExpiredPassword) {
		return { valid: true, msg: alertMsg }
	} else if (!isEmailSet) {
		alertMsg = 'Email is not set. Please contact Admin!'
	} else if (!isUsernameSet) {
		alertMsg = 'Please update Username from Profile!'
	} else if (!isPasswordSet) {
		alertMsg = 'Please update password from Profile!'
	} else if (!isExpiredPassword) {
		alertMsg = 'Password expired. Please update password from Profile!'
	}
	return { valid: false, msg: alertMsg }
}

export const formatNumberToDecimal = n => {
	let parts = n.toString().split('.')
	if (parts[0].length > 3) {
		let firstPart = parts[0].slice(0, parts[0].length - 3)
		let lastThree = parts[0].slice(parts[0].length - 3)
		parts[0] = firstPart.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + ',' + lastThree
		return parts.join('.')
	}
	return n
}

export const isIpadPro = () => {
	var ratio = window.devicePixelRatio || 1
	var screen = {
		width: window.screen.width * ratio,
		height: window.screen.height * ratio,
	}
	return (
		(screen.width === 2048 && screen.height === 2732) ||
		(screen.width === 2732 && screen.height === 2048) ||
		(screen.width === 1536 && screen.height === 2048) ||
		(screen.width === 2048 && screen.height === 1536)
	)
}

export const numberWithCommas = val => {
	if (!val || isNaN(parseFloat(val))) return val
	return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const toReturnLeavesCount = (valArray, keyVal) => {
	return valArray.map(val => Number(val[keyVal])).reduce((a, b) => a + b)
}

export const getShortUrlId = async (custEmail, jsonData) => {
	const { form_id, activity_id, form_flag_prefill_enabled, ...rest } = jsonData
	let reqData = {
		organization_id: jsonData.organization_id,
		account_id: jsonData.account_id,
		workforce_id: jsonData.workforce_id,
		asset_id: jsonData.asset_id,
		auth_asset_id: 31347,
		form_id,
		activity_id,
		asset_token_auth: '05986bb0-e364-11e8-a1c0-0b6831833754',
		url_form_data: JSON.stringify({
			form_id,
			activity_id,
			form_flag_prefill_enabled,
			...rest,
		}),
		form_flag_prefill_enabled: form_flag_prefill_enabled,
		url_mail_receivers: custEmail,
	}

	let response = await new Promise(async (resolve, reject) => {
		await shortenUrl(reqData).then(res => {
			if (res.data.response.length > 0) {
				resolve(res.data.response[0])
			} else {
				reject(reqData)
			}
		})
	})
	return response
}

export const parseJSONString = str => {
	try {
		if (typeof str === 'string') {
			const json = JSON.parse(str)
			return json
		}
	} catch (e) {}
	return false
}

export const rag_status = {
	red: 'red',
	green: localStorage.getItem('color'),
	amber: 'yellow',
}

export const currencyFormat = new Intl.NumberFormat('en-IN', {
	// style: 'currency',
	// currency: 'INR',
	// maximumSignificantDigits: 3,
	// These options are needed to round to whole numbers if that's what you want.
	// minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	// maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})
export const hasImageOrVideoUrl = url => {
	const extension = url?.split('.')?.pop()?.toLowerCase()
	const imageExtensions = [
		'jpg',
		'jpeg',
		'png',
		'gif',
		'bmp',
		'tiff',
		'svg',
		'mp4',
		'avi',
		'mov',
		'wmv',
		'flv',
		'xlsb',
		'ppt',
		'pptx',
		'pdf',
		'doc',
		'docx',
		'xls',
		'xlsx',
		'jfif',
	]

	if (imageExtensions?.includes(extension)) {
		return true
	} else {
		return false
	}
}

export const validURL = str => {
	var res = str.match(
		/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
	)
	return res !== null
}

export const isLocalhost = () => {
	return Boolean(
		window.location.hostname === 'localhost' ||
			// [::1] is the IPv6 localhost address.
			window.location.hostname === '[::1]' ||
			// 127.0.0.0/8 are considered localhost for IPv4.
			window.location.hostname.match(
				/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
			)
	)
}
