export const GRENE_OS_LOGIN = '__gr__l_'

export const AUTH_PAYLOAD = `${GRENE_OS_LOGIN}auth_p`
export const AUTH_C_CODE = `${GRENE_OS_LOGIN}c_code`
export const AUTH_P_CODE = `${GRENE_OS_LOGIN}p_code`
export const AUTH_E_CODE = `${GRENE_OS_LOGIN}e_code`
export const AUTH_SESSION_CHECK = `${GRENE_OS_LOGIN}session_active`
export const AUTH_USER_CG_TOKEN_KEY = `${GRENE_OS_LOGIN}cognito_key`
export const AUTH_BUCKET_NAME = `${GRENE_OS_LOGIN}bucket_name`
export const AUTH_LOGIN_MODE = `${GRENE_OS_LOGIN}login_mode_otp`
export const AUTH_SESSION_DATA = `${GRENE_OS_LOGIN}cognito_session`

export const AUTH_ASSET_TOKEN_AUTH = `${GRENE_OS_LOGIN}asset_token_auth`
export const AUTH_ORGANIZATION_ID = `${GRENE_OS_LOGIN}organization_id`
export const AUTH_ACCOUNT_ID = `${GRENE_OS_LOGIN}account_id`
export const AUTH_WORKFORCE_ID = `${GRENE_OS_LOGIN}workforce_id`
export const AUTH_ASSET_ID = `${GRENE_OS_LOGIN}asset_id`
export const AUTH_ASSET_IMAGE_PATH = `${GRENE_OS_LOGIN}asset_image_path`
export const AUTH_ASSET_TYPE_ID = `${GRENE_OS_LOGIN}asset_type_id`
export const AUTH_ORGANIZATION_LOGO = `${GRENE_OS_LOGIN}org_logo`
export const S3_BUCKET_NAME = `${GRENE_OS_LOGIN}bucket_name`
export const ORGANIZATION_ONHOLD = `ORGANIZATION_ONHOLD`
export const IS_EXTERNAL = `IS_EXTERNAL`
export const ORG_FLAG_GRID_ENABLE = `${GRENE_OS_LOGIN}organization_flag_enable_grid`
export const IS_WEBLOGIN_LINK = `IS_WEBLOGIN_LINK`
export const ORGANIZATION_ENABLE_RESOURCE_FILTER = `ORGANIZATION_ENABLE_RESOURCE_FILTER`
export const ORGANIZATION_SAVED_IMAGE_PATH = `${GRENE_OS_LOGIN}organization_saved_image_path`

export const LS_LOGIN_MODE = {
	QR: 0,
	OTP: 1,
	EMAIL: 2,
	AZURE_AD: 3,
}
