export const loginConst = {
	LOGIN: 'LOGIN',
	LOGIN_IN_PROGRESS: 'LOGINLOGIN_IN_PROGRESS',
	LOGIN_IN_FAILED: 'LOGIN_IN_FAILED',
	SAVE_USER_AUTH: 'SAVE_USER_AUTH',
	SETUSER: 'SETUSER',
	LOGOUT: 'LOGOUT',
	COGNITO_SIGN: 'COGNITO_SIGN',
	COGNITO_VERIFY: 'COGNITO_VERIFY',
	SET_BUCKET_NAME: 'SET_BUCKET_NAME',
	IS_AZURE_LOGIN: 'IS_AZURE_LOGIN',
}

export const sharedConst = {
	RESET_TO_TIMELINE: 'RESET_TO_TIMELINE',
	REFRESH_TIMELINE: 'REFRESH_TIMELINE',
	REFRESH_LISTING: 'REFRESH_LISTING',
	RESET_REFRESH_LISTING: 'RESET_REFRESH_LISTING',
	RESET_REFRESH_TIMELINE: 'RESET_REFRESH_TIMELINE',
	ACTVITY_SELECTED: 'ACTVITY_SELECTED',
	ACTIVITY_TYPE_DETAILS: 'ACTIVITY_TYPE_DETAILS',
	CLEAR_ACTIVITY_SELECTION: 'CLEAR_ACTIVITY_SELECTION',
	FETCH_FORM_FIELDS_LIST: 'FETCH_FORM_FIELDS_LIST',
	UPDATE_FORM_FIELDS_LIST: 'UPDATE_FORM_FIELDS_LIST',
	SET_SELECTED_QUEUE: 'SET_SELECTED_QUEUE',
	SET_ACTION_PANEL_VIEW: 'SET_ACTION_PANEL_VIEW',
	SET_SELECTED_FILTER: 'SET_SELECTED_FILTER',
	REFRESH_BROADCAST_COUNT: 'REFRESH_BROADCAST_COUNT',
	REFRESH_SELECTED_ACTIVITY: 'REFRESH_SELECTED_ACTIVITY',
	TIME_LINE_FILTER: 'TIME_LINE_FILTER',
	INITIAL_TIME_LINE_FILTER: 'INITIAL_TIME_LINE_FILTER',
	SET_PARTICIPANT_ACCESS_ID: 'SET_PARTICIPANT_ACCESS_ID',
	REFRESH_CHAT_COUNT: 'REFRESH_CHAT_COUNT',
	REFRESH_CHAT_LIST: 'REFRESH_CHAT_LIST',
	SELECTED_CHAT_PROFILE: 'SELECTED_CHAT_PROFILE',
	ASSIGN_REPORTING_MANAGER_ACCESS: 'ASSIGN_REPORTING_MANAGER_ACCESS',
	REFRESH_DOC_REPO: 'REFRESH_DOC_REPO',
	SHOW_LOADER: 'SHOW_LOADER',
	HIDE_LOADER: 'HIDE_LOADER',
	REFRESH_GANNT_VIEW: 'REFRESH_GANNT_VIEW',
	FORM_ONFLY_ADD_DATA: 'FORM_ONFLY_ADD_DATA',
	FORM_ONFLY_DATA_TYPE_ID: 'FORM_ONFLY_DATA_TYPE_ID',
	FORM_ONFLY_FIELD_ID: 'FORM_ONFLY_FIELD_ID',
	HANDLE_WHATSNEXT: 'HANDLE_WHATSNEXT',
	DS_RECORD: `DS_RECORD`,
}

export const drilldownConst = {
	SAVE_FILTER_STATUS: 'SAVE_FILTER_STATUS',
}

export const dashboardConst = {
	SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
	UPDATE_QUERY_FILTERS: 'UPDATE_QUERY_FILTERS',
	LOAD_WIDGETS: 'LOAD_WIDGETS',
	CLEAR_WIDGETS: 'CLEAR_WIDGETS',
	ADD_SIP_RESOURCE: 'ADD_SIP_RESOURCE',
	SET_SIP_RESOURCE: 'SET_SIP_RESOURCE',
	REMOVE_SIP_RESOURCE: 'REMOVE_SIP_RESOURCE',
	ADD_LEADERBOARDS: 'ADD_LEADERBOARDS',
	ADD_TOPDOWN: 'ADD_TOPDOWN',
	ADD_BOTTOMUP: 'ADD_BOTTOMUP',
	UPDATE_TOPDOWN: 'UPDATE_TOPDOWN',
	UPDATE_BOTTOMUP: 'UPDATE_BOTTOMUP',
	ADD_KPI_CODES: 'ADD_KPI_CODES',
	ADD_REPORTEES: 'ADD_REPORTEES',
	ADD_ACCOUNTS: 'ADD_ACCOUNTS',
	CLEAR_REPORTEE_STACK: 'CLEAR_REPORTEE_STACK',
	ADD_ACCOUNT_VIEWS: 'ADD_ACCOUNT_VIEWS',
	SCROLL_BOTTOM: 'SCROLL_BOTTOM',
	ADD_ACTIVE_FILTER_OPTION: 'ADD_ACTIVE_FILTER_OPTION',
	SET_TOOLTIP_LOADER: 'SET_TOOLTIP_LOADER',
	SET_TOOLTIP_ACTIVITY: 'SET_TOOLTIP_ACTIVITY',
	CLEAR_QUERY_FILTERS: 'CLEAR_QUERY_FILTERS',
	//UPDATE_WIDGET_DATA: 'UPDATE_WIDGET_DATA',
}

export const smartFormConst = {
	SET_FIELD_VALUE: 'SET_FIELD_VALUE',
	CLEAR_FIELDS: 'CLEAR_FIELDS',
	SET_FIELD_VALUES: 'SET_FIELD_VALUES',
	TO_REFRESH_NUM_VAL: 'TO_REFRESH_NUM_VAL',
	ON_MODEL_OPEN: 'ON_MODEL_OPEN',
	ON_MODEL_CLOSE: 'ON_MODEL_CLOSE',
	SET_DELETE_ID: 'SET_DELETE_ID',
}

export const localizationConst = {
	SET_SELECTED_LANGUAGE: 'SET_SELECTED_LANGUAGE',
}

export const outlookConst = {
	SET: 'YES',
	NOT_SET: 'NO',
}
