import AWS from 'aws-sdk'
import { getLocalAuthDetails, getMessageUniqueId } from './common.util'
import { AUTH_BUCKET_NAME, AUTH_LOGIN_MODE } from './constant'
import { getMonth, getYear } from './date.utils'
import { LoadS3CredsFiles } from '../hooks/shared.hooks'

const isMobileView = localStorage.getItem('IS_MOBILE_VIEW')

export const extractS3Url = url => {
	return new Promise(resolve => {
		try {
			let urlParts
			if (url.indexOf('ap-south') > 1) {
				urlParts = url.split('.s3.ap-south-1.amazonaws.com/')
			} else {
				urlParts = url.split('.s3.amazonaws.com/')
			}

			let keyParts = urlParts[1].split('/')
			let BucketName = urlParts[0].replace('https://', '')
			let KeyName = urlParts[1]
			let FileName = keyParts[keyParts.length - 1]

			resolve([BucketName, KeyName, FileName])
		} catch (err) {
			resolve(['', '', ''])
		}
	})
}

export const prefix = () => {
	let { organization_id, account_id, workforce_id, asset_id } =
		getLocalAuthDetails()
	return `${organization_id}/${account_id}/${workforce_id}/${asset_id}/${getYear(
		new Date()
	)}/${getMonth(new Date())}/103/${getMessageUniqueId()}`
}

export const pushFileToS3 = async (
	file,
	filePath = null,
	bucketName = null
) => {
	await LoadS3CredsFiles()
	let s3 = new AWS.S3()
	let bucket = localStorage.getItem(AUTH_BUCKET_NAME)
	let key = filePath ? `${filePath}${file.name}` : `${prefix()}/${file.name}`
	let params = {
		Bucket: bucketName ? bucketName : bucket,
		Key: key,
		Body: file,
		ContentType: file.type,
		ACL: 'private',
	}

	try {
		await s3.putObject(params).promise()
		return {
			url: `https://${params.Bucket}.s3.ap-south-1.amazonaws.com/${params.Key}`,
		}
	} catch (error) {
		console.error(error.message)
		return { isError: true, message: error.message }
	}
}

export const downloadAwsFile = async fileUrl => {
	if (isMobileView) {
		return
	} else {
		await LoadS3CredsFiles()
		let s3 = new AWS.S3()
		const [BucketName, KeyName, FileName] = await extractS3Url(fileUrl)
		s3.getObject(
			{
				Bucket: BucketName,
				Key: KeyName,
			},
			(error, res) => {
				if (error) {
					console.log('Error occured while downloading files', error)
				} else {
					const ContentType = res.ContentType
					const newBlob = new Blob([res.Body], { type: ContentType })
					if (window.navigator && window.navigator.msSaveOrOpenBlob) {
						window.navigator.msSaveOrOpenBlob(newBlob)
						return
					}
					const data = window.URL.createObjectURL(newBlob)
					var link = document.createElement('a')
					link.href = data
					link.download = FileName
					link.click()
				}
			}
		)
	}
}

export const previewAwsFile = async (fileUrl, cb) => {
	await LoadS3CredsFiles()
	let s3 = new AWS.S3()
	const [BucketName, KeyName, FileName] = await extractS3Url(fileUrl)
	s3.getObject(
		{
			Bucket: BucketName,
			Key: KeyName,
		},
		(error, res) => {
			if (error) {
				console.log('Error occured while downloading files', error)
			} else {
				const ContentType = res.ContentType
				const newBlob = new Blob([res.Body], { type: ContentType })
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(newBlob)
					return
				}
				const data_url = window.URL.createObjectURL(newBlob)
				cb({
					type: ContentType,
					filename: FileName,
					data_url: data_url,
				})
				//
				// if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				// 	window.navigator.msSaveOrOpenBlob(newBlob)
				// 	return
				// }
				// const data = window.URL.createObjectURL(newBlob)
				// cb({ url: data, filename: FileName })
			}
		}
	)
}

const encode = data => {
	if (!data) {
		return
	}
	let str = data.reduce(function (a, b) {
		return a + String.fromCharCode(b)
	}, '')
	return btoa(str).replace(/.{76}(?=.)/g, '$&\n')
}

export const loadAwsFile = async (fileUrl, cb) => {
	await LoadS3CredsFiles()
	let s3 = new AWS.S3()
	const [BucketName, KeyName, FileName] = await extractS3Url(fileUrl)
	await s3.getObject(
		{
			Bucket: BucketName,
			Key: KeyName,
		},
		(error, res) => {
			if (error) {
				console.log('Error occured while downloading files', error)
			} else {
				cb({
					type: res.ContentType,
					data: encode(res.Body),
					name: FileName,
				})
			}
		}
	)
}

export const deleteAwsFile = async fileUrl => {
	await LoadS3CredsFiles()
	let s3 = new AWS.S3()
	const [BucketName, KeyName, FileName] = await extractS3Url(fileUrl)
	s3.deleteObject(
		{
			Bucket: BucketName,
			Key: KeyName,
		},
		(error, res) => {
			if (error) {
				console.log('Error occurred while deleting files', error)
			} else {
				console.log('Deleted file successfully')
				return {
					type: res.ContentType,
					data: res,
				}
			}
		}
	)
}

export const loadImageBlob = async (url, cb) => {
	await LoadS3CredsFiles()
	let s3 = new AWS.S3()
	const [BucketName, KeyName] = await extractS3Url(url)
	s3.getObject(
		{
			Bucket: BucketName,
			Key: KeyName,
		},
		(err, blob) => {
			if (err) {
			} else {
				cb(blob.Body, blob.ContentType)
			}
		}
	)
}
