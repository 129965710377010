import { Amplify } from 'aws-amplify'
import { AUTH_LOGIN_MODE, LS_LOGIN_MODE } from './constant'
import { isLocalhost } from './common.util'

export const configureAmplify = () => {
	let loginMode = Number(sessionStorage.getItem(AUTH_LOGIN_MODE))
	if (loginMode === LS_LOGIN_MODE.QR) {
		Amplify.configure({
			Auth: {
				mandatorySignIn: true,
				userPoolId: process.env.REACT_APP_USER_POOL_ID,
				identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
				userPoolWebClientId: process.env.REACT_APP_WEB_NO_SECRETE_CLIENT_ID,
				region: process.env.REACT_APP_AWS_REGION,
			},
			Analytics: {
				disabled: true,
			},
		})
	} else if (loginMode === LS_LOGIN_MODE.OTP) {
		Amplify.configure({
			Auth: {
				mandatorySignIn: true,
				userPoolId: process.env.REACT_APP_OTP_USER_POOL_ID,
				identityPoolId: process.env.REACT_APP_OTP_IDENTITY_POOL_ID,
				userPoolWebClientId: process.env.REACT_APP_OTP_WEB_NO_SECRETE_CLIENT_ID,
				region: process.env.REACT_APP_OTP_AWS_REGION,
			},
			Analytics: {
				disabled: true,
			},
		})
	} else if (loginMode === LS_LOGIN_MODE.EMAIL) {
		Amplify.configure({
			Auth: {
				mandatorySignIn: true,
				userPoolId: process.env.REACT_APP_EMAIL_USER_POOL_ID,
				identityPoolId: process.env.REACT_APP_EMAIL_IDENTITY_POOL_ID,
				userPoolWebClientId:
					process.env.REACT_APP_EMAIL_WEB_NO_SECRETE_CLIENT_ID,
				region: process.env.REACT_APP_EMAIL_AWS_REGION,
			},
			Analytics: {
				disabled: true,
			},
		})
	} else if (loginMode === LS_LOGIN_MODE.AZURE_AD) {
		Amplify.configure({
			Auth: {
				identityPoolId: `${process.env.REACT_APP_AD_IDENTITY_POOL_ID}`,
				region: `${process.env.REACT_APP_AWS_REGION}`,
				userPoolId: `${process.env.REACT_APP_AD_USER_POOL_ID}`,
				userPoolWebClientId: `${process.env.REACT_APP_AZURE_AD_WEB_NO_SECRETE_CLIENT_ID}`,
				oauth: {
					domain: `${process.env.REACT_APP_AD_DOMAIN_NAME}`,
					scope: ['email', 'openid'],
					redirectSignIn: isLocalhost()
						? 'http://localhost:3000/login'
						: process.env.REACT_APP_REDIRECT_SIGNIN_URL,
					redirectSignOut: process.env.REACT_APP_REDIRECT_SIGNOUT_URL,
					responseType: `${process.env.REACT_APP_AD_RESPONSE_TYPE}`,
					label: 'Log in with your company SSO',
					clientId: `${process.env.REACT_APP_AZURE_AD_WEB_NO_SECRETE_CLIENT_ID}`,
				},
			},
			Analytics: {
				disabled: true,
			},
		})
	}
}
